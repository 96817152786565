import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Title from "../../components/Titler";
import my_animation from '../../animate';
import DP1 from '../../assets/sy-dp1.png';
import DP2 from '../../assets/sy-dp2.png';
import DP3 from '../../assets/sy-dp3.png';
import DP4 from '../../assets/sy-dp4.png';
import DP5 from '../../assets/sy-dp5.png';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import './index.scss';

const pics = [DP1, DP2, DP3, DP4, DP5]
SwiperCore.use([Pagination, Navigation, Autoplay]);

export default () => {
  const [visible, setVisible] = useState(false)
  const [modalIndex, setModalIndex] = useState(0)
  
  useEffect(() => {
    my_animation()
  }, [])

  const showModal = (index) => {
    setModalIndex(index)
    setVisible(true)
  }
  
  const closeModal = () => {
    setVisible(false)
  }

  return (
    <div className="wisdom-page">
      <Header />
      <Title title="数字城市与智慧楼宇" icon="&#xe638;" size={36} />
      <p className="intro-txt animate fadeInUp1">城市数字化管理需打造一个统一平台，设立城市数据中心，通过分层建设，达到平台能力及应用的可成长、可补充，创造面向未来的智慧城市系统框架。中壬建筑设计有限公司针对智能化项目设计，以新建或改造建筑为平台，基于对各类智能化信息的综合应用，集架构、系统、应用、管理及优化组合为一体，形成以人、建筑、环境互为协调的整合体，为客户营造安全、高效、便利及具备可持续发展功能的建筑空间。</p>
      <div className="intro">
        <ul>
          <li className="animate" style={{animationDelay: '0s'}}>智慧建筑物业管理</li>
          <li className="animate" style={{animationDelay: '.1s'}}>能源使用（主动/被动）</li>
          <li className="animate" style={{animationDelay: '.2s'}}>建筑全过程服务</li>
          <li className="animate" style={{animationDelay: '.3s'}}>客户服务，空间管理</li>
          <li className="animate" style={{animationDelay: '.4s'}}>垃圾智能管理</li>
          <li className="animate" style={{animationDelay: '.5s'}}>环境实时监测报告</li>
          <li className="animate" style={{animationDelay: '.6s'}}>数据中心</li>
        </ul>
        <img className="animate fadeInUp1" src={require('../../assets/wisdom-pic1.png').default} />
        <ul className="intro-right">
          <li className="animate" style={{animationDelay: '0s'}}>信息引导发布系统</li>
          <li className="animate" style={{animationDelay: '.1s'}}>建筑设备监控系统</li>
          <li className="animate" style={{animationDelay: '.2s'}}>智能照明系统</li>
          <li className="animate" style={{animationDelay: '.3s'}}>停车管理系统</li>
          <li className="animate" style={{animationDelay: '.4s'}}>视频监控系统</li>
          <li className="animate" style={{animationDelay: '.5s'}}>访客管理系统</li>
          <li className="animate" style={{animationDelay: '.6s'}}>电梯运营监测系统</li>
        </ul>
      </div>
      <div className="wisdom-page-sys">
        <div className="sys-list">
          <ul>
            <li className="title animate">系统集成平台（IBMS)</li>
            <li className="rank animate" style={{animationDelay: '0s'}}>01</li>
            <li className="txt animate" style={{animationDelay: '.1s'}}>信息引导发布系统</li>
            <li className="txt animate" style={{animationDelay: '.15s'}}>建筑设计监控系统</li>
            <li className="txt animate" style={{animationDelay: '.2s'}}>智能照明系统</li>
            <li className="txt animate" style={{animationDelay: '.25s'}}>建筑能效监管系统</li>
            <li className="txt animate" style={{animationDelay: '.3s'}}>电梯五方对讲系统</li>
            <li className="txt animate" style={{animationDelay: '.35s'}}>出入口控制系统</li>
            <li className="txt animate" style={{animationDelay: '.4s'}}>周界报警系统</li>
            <li className="txt animate" style={{animationDelay: '.45s'}}>访客管理系统</li>
            <li className="txt animate" style={{animationDelay: '.5s'}}>楼宇对讲系统</li>
            <li className="txt animate" style={{animationDelay: '.55s'}}>停车场管理系统</li>
            <li className="txt animate" style={{animationDelay: '.6s'}}>客流统计系统</li>
            <li className="txt animate" style={{animationDelay: '.65s'}}>电梯运行监测系统</li>
          </ul>
          <ul>
            <li className="title animate">可视化运营管理平台</li>
            <li className="rank animate" style={{animationDelay: '0s'}}>02</li>
            <li className="txt animate" style={{animationDelay: '.1s'}}>智能楼控可视化</li>
            <li className="txt animate" style={{animationDelay: '.15s'}}>三维空间可视化</li>
            <li className="txt animate" style={{animationDelay: '.2s'}}>智慧安防可视化</li>
            <li className="txt animate" style={{animationDelay: '.25s'}}>智慧消防可视化</li>
            <li className="txt animate" style={{animationDelay: '.3s'}}>能耗管理可视化</li>
            <li className="txt animate" style={{animationDelay: '.35s'}}>告警管理可视化</li>
          </ul>
          <ul>
            <li className="title animate">智慧管理平台</li>
            <li className="rank animate" style={{animationDelay: '0s'}}>03</li>
            <li className="txt animate" style={{animationDelay: '.1s'}}>物联网服务平台</li>
            <li className="txt animate" style={{animationDelay: '.15s'}}>应用协同管理平台</li>
            <li className="txt animate" style={{animationDelay: '.2s'}}>大数据分析平台</li>
          </ul>
          <ul>
            <li className="title animate">多媒体会议系统</li>
            <li className="rank animate" style={{animationDelay: '0s'}}>04</li>
            <li className="txt animate" style={{animationDelay: '.1s'}}>数字会议系统</li>
            <li className="txt animate" style={{animationDelay: '.15s'}}>远程视频会议系统</li>
            <li className="txt animate" style={{animationDelay: '.2s'}}>视频显示系统</li>
            <li className="txt animate" style={{animationDelay: '.25s'}}>会场扩声系统</li>
            <li className="txt animate" style={{animationDelay: '.3s'}}>集中控制系统</li>
            <li className="txt animate" style={{animationDelay: '.35s'}}>视频矩阵切换系统</li>
            <li className="txt animate" style={{animationDelay: '.4s'}}>LED大屏显示系统</li>
            <li className="txt animate" style={{animationDelay: '.45s'}}>综合布线</li>
          </ul>
          <ul>
            <li className="title animate">智能机房系统</li>
            <li className="rank animate" style={{animationDelay: '0s'}}>05</li>
            <li className="txt animate" style={{animationDelay: '.1s'}}>UPS供电系统</li>
            <li className="txt animate" style={{animationDelay: '.15s'}}>机房环境监测系统</li>
            <li className="txt animate" style={{animationDelay: '.2s'}}>机房气体灭火系统</li>
            <li className="txt animate" style={{animationDelay: '.25s'}}>机房火灾自动报警系统</li>
            <li className="txt animate" style={{animationDelay: '.3s'}}>机房供电系统</li>
            <li className="txt animate" style={{animationDelay: '.35s'}}>机房防雷与接地系统</li>
          </ul>
          <ul>
            <li className="title animate">智能化特别设计</li>
            <li className="rank animate" style={{animationDelay: '0s'}}>06</li>
            <li className="txt animate" style={{animationDelay: '.1s'}}>疫情防控设计</li>
            <li className="txt animate" style={{animationDelay: '.15s'}}>无感通行设计</li>
            <li className="txt animate" style={{animationDelay: '.2s'}}>卫生间厕位人员占用监测</li>
            <li className="txt animate" style={{animationDelay: '.25s'}}>预警防撞设计</li>
            <li className="txt animate" style={{animationDelay: '.3s'}}>智慧灯杆</li>
            <li className="txt animate" style={{animationDelay: '.35s'}}>智能井盖</li>
            <li className="txt animate" style={{animationDelay: '.4s'}}>夜景照明系统</li>
          </ul>
        </div>
      </div>
      <Title title="智慧大屏案例展示" />
      <div className="auto">
        {
          pics.map((item, index) => {
            return <img key={index} onClick={() => showModal(index)} className="animate" src={item} />
          })
        }
      </div>
      <Footer />
      {
        visible ? 
        <div className="modal">
          <div className="modal-container">
            <Swiper
              slidesPerView={1}
              initialSlide={modalIndex}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
                pauseOnMouseEnter: false
              }}
              navigation={{
                prevEl: '.prev',
                nextEl: '.next'
              }}
            >
              {
                pics.map((item, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <div className="pic-box">
                        <img className="pic" src={item} />
                      </div>
                    </SwiperSlide>
                  )
                })
              }
              <div className="prev iconfont">&#xe75e;</div>
              <div className="next iconfont">&#xe75a;</div>
            </Swiper>
            <div className="modal-close" onClick={closeModal}></div>
          </div>
        </div>
        : null
      }
    </div>
  )
}