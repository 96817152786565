import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Title from "../../components/Titler";
import my_animation from '../../animate';

import PIC011 from '../../assets/project/01-1.jpg';
import PIC012 from '../../assets/project/01-2.jpg';
import PIC021 from '../../assets/project/02-1.jpg';
import PIC022 from '../../assets/project/02-2.jpg';
import PIC031 from '../../assets/project/03-1.jpg';
import PIC041 from '../../assets/project/04-1.jpg';
import PIC042 from '../../assets/project/04-2.jpg';
import PIC043 from '../../assets/project/04-3.jpg';
import PIC051 from '../../assets/project/05-1.jpg';
import PIC052 from '../../assets/project/05-2.jpg';
import PIC053 from '../../assets/project/05-3.jpg';
import PIC054 from '../../assets/project/05-4.jpg';
import PIC061 from '../../assets/project/06-1.jpg';
import PIC062 from '../../assets/project/06-2.jpg';
import PIC071 from '../../assets/project/07-1.jpg';
import PIC072 from '../../assets/project/07-2.jpg';
import PIC073 from '../../assets/project/07-3.jpg';
import PIC081 from '../../assets/project/08-1.jpg';
import PIC082 from '../../assets/project/08-2.jpg';
import PIC083 from '../../assets/project/08-3.jpg';
import PIC091 from '../../assets/project/09-1.jpg';
import PIC101 from '../../assets/project/10-1.jpg';
import PIC102 from '../../assets/project/10-2.jpg';
import PIC103 from '../../assets/project/10-3.jpg';
import PIC111 from '../../assets/project/11-1.jpg';
import PIC112 from '../../assets/project/11-2.jpg';
import PIC121 from '../../assets/project/12-1.jpg';
import PIC122 from '../../assets/project/12-2.jpg';
import PIC123 from '../../assets/project/12-3.jpg';
import PIC131 from '../../assets/project/13-1.jpg';
import PIC132 from '../../assets/project/13-2.jpg';
import PIC133 from '../../assets/project/13-3.jpg';
import PIC141 from '../../assets/project/14-1.jpg';
import PIC151 from '../../assets/project/15-1.jpg';
import PIC152 from '../../assets/project/15-2.jpg';
import PIC161 from '../../assets/project/16-1.jpg';
import PIC171 from '../../assets/project/17-1.jpg';
import PIC181 from '../../assets/project/18-1.jpg';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import './index.scss';

SwiperCore.use([Pagination, Navigation, Autoplay]);

export default () => {
  const pics = [
    [PIC011,PIC012], 
    [PIC021,PIC022], 
    [PIC031], 
    [PIC041,PIC042,PIC043], 
    [PIC051,PIC052,PIC053,PIC054], 
    [PIC061,PIC062], 
    [PIC071,PIC072,PIC073], 
    [PIC081,PIC082,PIC083], 
    [PIC091], 
    [PIC101,PIC102,PIC103], 
    [PIC111,PIC112], 
    [PIC121,PIC122,PIC123], 
    [PIC131,PIC132,PIC133], 
    [PIC141], 
    [PIC151,PIC152], 
    [PIC161], 
    [PIC171],
    [PIC181]
  ]
  const [visible, setVisible] = useState(false)
  const [modalIndex, setModalIndex] = useState(1)

  useEffect(() => {
    my_animation()
  }, [])

  const showModal = (index) => {
    setModalIndex(index - 1)
    setVisible(true)
  }

  const closeModal = () => {
    setVisible(false)
  }

  return (
    <div className="about-page">
      <Header />
      <Title title="关于我们" icon="&#xe63c;" size={30} />
      <p className="intro animate">中壬建筑设计有限公司，在建筑工程设计、工程施工和勘察领域都有丰富的行业积累和技术沉淀。公司始终秉承以人为本、质量第一、不断创新、优质服务的企业理念，凭借严谨的工作原则和优良的服务意识，在教育、办公、商业、医疗、城市规划、城市设计、园林设计、基桩工程设计地质测绘岩土勘察工程、概预算等方面都有丰富的经验。公司注重将核心团队技术能力与管理执行相结合，且秉承诚信为本、互利共赢的原则将来也会致力于更好的服务于客户及各地区合作伙伴。</p>
      <Title title="组织架构" icon="&#xe634;" size={28} />
      <div className="org">
        <ul className="animate" style={{animationDelay: 0}}>
          <li className="org-title">职能部门</li>
          <li className="org-txt">综合管理部</li>
          <li className="org-txt">技术质量部</li>
          <li className="org-txt">财务管理部</li>
          <li className="org-txt">信息档案部</li>
          <li className="org-txt">后勤保障部</li>
        </ul>
        <ul className="animate" style={{animationDelay: '.2s'}}>
          <li className="org-title">设计部门</li>
          <li className="org-txt">设计与研发中心</li>
          <li className="org-txt">施工图一所</li>
          <li className="org-txt">施工图二所</li>
        </ul>
        <ul className="animate" style={{animationDelay: '.4s'}}>
          <li className="org-title">专项设计中心</li>
          <li className="org-txt">智慧建筑中心</li>
          <li className="org-txt">EPC总承包中心</li>
          <li className="org-txt">BIM设计中心</li>
          <li className="org-txt">工程咨询中心</li>
          <li className="org-txt">室内设计中心</li>
        </ul>
      </div>
      <Title title="项目展示" icon="&#xe891;" size={30} />
      <div className="auto project">
        <Swiper
          slidesPerView={1}
          initialSlide={0}
          navigation={{
            prevEl: '.prev',
            nextEl: '.next'
          }}
        >
          <SwiperSlide>
            <div className="swiper-slider-box">
              <div className="img-box animate" style={{animationDelay: 0}} onClick={() => showModal(1)}>
                <img src={require('../../assets/project/01-1.jpg').default} />
                <p className="p-name">山西农谷农副食品加工园区</p>
              </div>
              <div className="img-box animate" style={{animationDelay: '.2s'}} onClick={() => showModal(2)}>
                <img src={require('../../assets/project/02-1.jpg').default} />
                <p className="p-name">平鲁区地理信息产业创新孵化基地</p>
              </div>
              <div className="img-box animate" style={{animationDelay: '.4s'}} onClick={() => showModal(3)}>
                <img src={require('../../assets/project/03-1.jpg').default} />
                <p className="p-name">朔城区人民法院</p>
              </div>
              <div className="img-box animate" style={{animationDelay: 0}} onClick={() => showModal(4)}>
                <img src={require('../../assets/project/04-1.jpg').default} />
                <p className="p-name">大同市大十字街区改造</p>
              </div>
              <div className="img-box animate" style={{animationDelay: '.2s'}} onClick={() => showModal(5)}>
                <img src={require('../../assets/project/05-1.jpg').default} />
                <p className="p-name">介休市汾秀学校建设项目</p>
              </div>
              <div className="img-box animate" style={{animationDelay: '.4s'}} onClick={() => showModal(6)}>
                <img src={require('../../assets/project/06-1.jpg').default} />
                <p className="p-name">介休市体育馆</p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-slider-box">
              <div className="img-box" onClick={() => showModal(7)}>
                <img src={require('../../assets/project/07-1.jpg').default} />
                <p className="p-name">北京新学道介休书院</p>
              </div>
              <div className="img-box" onClick={() => showModal(8)}>
                <img src={require('../../assets/project/08-1.jpg').default} />
                <p className="p-name">朔州市朔城区职业中学</p>
              </div>
              <div className="img-box" onClick={() => showModal(9)}>
                <img src={require('../../assets/project/09-1.jpg').default} />
                <p className="p-name">平鲁区高级中学</p>
              </div>
              <div className="img-box" onClick={() => showModal(10)}>
                <img src={require('../../assets/project/10-1.jpg').default} />
                <p className="p-name">新时代开发路综合楼</p>
              </div>
              <div className="img-box" onClick={() => showModal(11)}>
                <img src={require('../../assets/project/11-1.jpg').default} />
                <p className="p-name">平鲁区信息产业园</p>
              </div>
              <div className="img-box" onClick={() => showModal(12)}>
                <img src={require('../../assets/project/12-1.jpg').default} />
                <p className="p-name">英国伦敦佩克汉姆区鱼菜共生产业园区</p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-slider-box">
              <div className="img-box" onClick={() => showModal(13)}>
                <img src={require('../../assets/project/13-1.jpg').default} />
                <p className="p-name">斗拱结构可行性拓展研究</p>
              </div>
              <div className="img-box" onClick={() => showModal(14)}>
                <img src={require('../../assets/project/14-1.jpg').default} />
                <p className="p-name">太原基督教五一路教堂商务中心</p>
              </div>
              <div className="img-box" onClick={() => showModal(15)}>
                <img src={require('../../assets/project/15-1.jpg').default} />
                <p className="p-name">朔州市朔城区大营街25号院基督教堂</p>
              </div>
              <div className="img-box" onClick={() => showModal(16)}>
                <img src={require('../../assets/project/16-1.jpg').default} />
                <p className="p-name">宋金墓葬博物馆</p>
              </div>
              <div className="img-box img-box1" onClick={() => showModal(17)}>
                <img src={require('../../assets/project/17-1.jpg').default} />
                <p className="p-name">朔州市七里河公园景观规划设计</p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-slider-box">
              <div className="img-box2" onClick={() => showModal(18)}>
                <img src={require('../../assets/project/18-1.jpg').default} />
              </div>
            </div>
          </SwiperSlide>
          <div className="prev iconfont">&#xe622;</div>
          <div className="next iconfont">&#xe6d0;</div>
        </Swiper>
      </div>
      <Title title="业务领域" icon="&#xe60c;" />
      <div className="ywly-sec">
        <div className="ywly-content">
          <div className="left">
            <p className="title animate">建筑设计</p>
            <p className="rank animate">01</p>
            <img className="animate" height={75} src={require('../../assets/about-ywly-icon1.png').default} />
          </div>
          <div className="right">
            <ul>
              <li className="animate" style={{animationDelay: 0}}>教育建筑</li>
              <li className="animate" style={{animationDelay: '.1s'}}>办公建筑</li>
              <li className="animate" style={{animationDelay: '.2s'}}>居住建筑</li>
              <li className="animate" style={{animationDelay: '.3s'}}>文化与博览</li>
              <li className="animate" style={{animationDelay: '.4s'}}>酒店与商业</li>
              <li className="animate" style={{animationDelay: '.5s'}}>医疗与康养</li>
              <li className="animate" style={{animationDelay: '.6s'}}>物流与产业园</li>
              <li className="animate" style={{animationDelay: '.7s'}}>TOD设计</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="ywly-sec">
        <div className="ywly-content">
          <div className="left">
            <p className="title animate">市政工程</p>
            <p className="rank animate">02</p>
            <img className="animate" height={18} src={require('../../assets/about-ywly-icon2.png').default} />
          </div>
          <div className="right">
            <ul>
              <li className="animate" style={{animationDelay: 0}}>市政工程规划</li>
              <li className="animate" style={{animationDelay: '.1s'}}>道路交通规划</li>
              <li className="animate" style={{animationDelay: '.2s'}}>综合防灾规划</li>
              <li className="animate" style={{animationDelay: '.3s'}}>市政给排水设计</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="ywly-sec">
        <div className="ywly-content">
          <div className="left">
            <p className="title animate">城乡规划</p>
            <p className="rank animate">03</p>
            <img className="animate" height={53} src={require('../../assets/about-ywly-icon3.png').default} />
          </div>
          <div className="right">
            <ul>
              <li className="animate" style={{animationDelay: '0s'}}>总体规划</li>
              <li className="animate" style={{animationDelay: '.1s'}}>详细规划</li>
              <li className="animate" style={{animationDelay: '.2s'}}>城市设计</li>
              <li className="animate" style={{animationDelay: '.3s'}}>专项规划</li>
              <li className="animate" style={{animationDelay: '.4s'}}>旅游规划</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="ywly-sec">
        <div className="ywly-content">
          <div className="left">
            <p className="title animate">咨询总包</p>
            <p className="rank animate">04</p>
            <img className="animate" height={72} src={require('../../assets/about-ywly-icon4.png').default} />
          </div>
          <div className="right">
            <ul>
              <li className="animate" style={{animationDelay: '0s'}}>全过程咨询</li>
              <li className="animate" style={{animationDelay: '.1s'}}>设计咨询</li>
              <li className="animate" style={{animationDelay: '.2s'}}>图审咨询</li>
              <li className="animate" style={{animationDelay: '.3s'}}>EPC总承包</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="ywly-sec" style={{marginBottom: 106}}>
        <div className="ywly-content">
          <div className="left">
            <p className="title animate">智慧建筑</p>
            <p className="rank animate">05</p>
            <img className="animate" height={96} src={require('../../assets/about-ywly-icon5.png').default} />
          </div>
          <div className="right">
            <ul>
              <li className="animate" style={{animationDelay: '0s'}}>智慧楼宇</li>
              <li className="animate" style={{animationDelay: '.1s'}}>BIM设计</li>
              <li className="animate" style={{animationDelay: '.3s'}}>绿建设计</li>
              <li className="animate" style={{animationDelay: '.4s'}}>装配式设计</li>
              <li className="animate" style={{animationDelay: '.5s'}}>室内设计</li>
              <li className="animate" style={{animationDelay: '.6s'}}>照明设计</li>
              <li className="animate" style={{animationDelay: '.7s'}}>电力设计</li>
              <li className="animate" style={{animationDelay: '.8s'}}>海绵城市</li>
            </ul>
          </div>
        </div>
      </div>
      {
        visible ? 
        <div className="modal">
          <div className="modal-container">
            <Swiper
              slidesPerView={1}
              initialSlide={0}
              // loop={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
                pauseOnMouseEnter: false
              }}
              navigation={{
                prevEl: '.modal-prev',
                nextEl: '.modal-next'
              }}
            >
              {
                pics[modalIndex].map((item, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <div className="pic-box">
                        <img className="pic" src={item} />
                      </div>
                    </SwiperSlide>
                  )
                })
              }
              <div className="modal-prev iconfont">&#xe75e;</div>
              <div className="modal-next iconfont">&#xe75a;</div>
            </Swiper>
            <div className="modal-close" onClick={closeModal}></div>
          </div>
        </div>
        : null
      }
      <Footer />
    </div>
  )
}