import React, { useEffect } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Title from "../../components/Titler";
import my_animation from '../../animate';
import './index.scss';

export default () => {

  useEffect(() => {
    my_animation()
  }, [])

  return (
    <div className="honor-page">
      <Header />
      <Title title="荣誉资质" icon="&#xe72d;" size={30} />
      <ul className="pic-list">
        <li className="animate fadeInUp2" style={{animationDelay: '0s'}}>
          <img className="pic" src={require('../../assets/c1.jpeg').default} />
        </li>
        <li className="animate fadeInUp2" style={{animationDelay: '.1s'}}>
          <img className="pic" src={require('../../assets/c2.jpeg').default} />
        </li>
        <li className="animate fadeInUp2" style={{animationDelay: '.2s'}}>
          <img className="pic" src={require('../../assets/c3.jpeg').default} />
        </li>
        <li className="animate fadeInUp2" style={{animationDelay: '.3s'}}>
          <img className="pic" src={require('../../assets/c4.jpeg').default} />
        </li>
      </ul>
      <Footer />
    </div>
  )
}