import React, { useEffect } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import './index.scss';

const AMap = window.AMap;
export default () => {

  useEffect(() => {
    initBjMap()
    initSzMap()
  }, [])

	const initBjMap = () => {
		const mapDom = document.querySelector('#bjMap');
    const map = new AMap.Map(mapDom, {
      center: [116.308836, 39.981766],
      zoom: 17,
    })
    const marker = new AMap.Marker({
      icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png",
      position: [116.308836, 39.981766]
    });
    map.add(marker);
	};

	const initSzMap = () => {
		const mapDom = document.querySelector('#szMap');
    const map = new AMap.Map(mapDom, {
      center: [112.458664, 39.352913],
      zoom: 17,
    })
    const marker = new AMap.Marker({
      icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png",
      position: [112.458664, 39.352913]
    });
    map.add(marker);
	};

  return (
    <div className="contact-page">
      <Header />
      <div className="contact-page-title">
        <span className="iconfont" style={{fontSize: 30, color: '#999'}}>&#xe606;</span>
        <span>联系方式</span>
      </div>
      <div className="map-container">
        <div className="map-container-left">
          <div className="map-box" id="bjMap"></div>
          <p className="txt">联系地址：北京市海淀区彩和坊路8号天创科技大厦</p>
          <p className="txt">联系电话：400-998-2282</p>
        </div>
        <div className="map-container-right">
          <div className="map-box" id="szMap"></div>
          <p className="txt">联系地址：朔州市开发区文远路87号</p>
          <p className="txt">联系电话：400-998-2282</p>
        </div>
      </div>
      <Footer />
    </div>
  )
}