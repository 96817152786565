import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Title from "../../components/Titler";
import BANNER01 from '../../assets/banner/banner-01.jpg';
import BANNER02 from '../../assets/banner/banner-02.jpg';
import BANNER03 from '../../assets/banner/banner-03.jpg';
import BANNER04 from '../../assets/banner/banner-04.jpg';
import BANNER05 from '../../assets/banner/banner-05.jpg';
import ICONFASJ from '../../assets/sy-fasj.png';
import ICONGCSJ from '../../assets/sy-gcsj.png';
import ICONSZSJ from '../../assets/sy-szsj.png';
import ICONCXGH from '../../assets/sy-csgh.png';
import ICONEPC from '../../assets/sy-epc.png';
import ICONZHJZ from '../../assets/sy-zhjz.png';
import DP1 from '../../assets/sy-dp1.png';
import my_animation from '../../animate';

import 'swiper/swiper.scss';
import './index.scss';
SwiperCore.use([Pagination, Autoplay]);

const bannerList = [
  {
    img: BANNER01,
    name: '平鲁区地理信息产业创新孵化基地',
  }, 
  {
    img: BANNER02,
    name: '山西农谷农副食品加工园区'
  }, 
  {
    img: BANNER03,
    name: '介休市汾秀学校建设项目'
  }, 
  {
    img: BANNER04,
    name: '朔州市大营街25号院基督教堂'
  }, 
  {
    img: BANNER05,
    name: '大同市大十字街区改造'
  }
]


export default () => {
  const [bannerIndex, setBannerIndex] = useState('')
  const [content, setContent] = useState('')
  const [name, setName] = useState('')
  const [company, setCompany] = useState('')
  const [phone, setPhone] = useState('')
  const [code, setCode] = useState('')
  const [cCode, setCCode] = useState('')

  useEffect(() => {
    createCode()
    my_animation()
    setBannerIndex(0)
  }, [])

  const randomNum = (min, max) => {
    return Math.floor(Math.random() * (max - min) + min);
  }

  const createCode = () => {
    const c = document.getElementById('myCanvas')
    const ctx = c.getContext('2d')
    const chars = [1, 2, 3, 4, 5, 6, 7, 8, 9, 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'j', 'k', 'l', 'm', 'n', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
    let code = ''
    ctx.clearRect(0, 0, 80, 39)
    for (let i = 0; i < 4; i++) {
      const char = chars[randomNum(0, 57)]
      code += char
      ctx.font = randomNum(20, 25) + 'px SimHei'  //设置字体随机大小
      ctx.fillStyle = '#D3D7F7'
      ctx.textBaseline = 'middle'
      ctx.shadowOffsetX = randomNum(-3, 3)
      ctx.shadowOffsetY = randomNum(-3, 3)
      ctx.shadowBlur = randomNum(-3, 3)
      ctx.shadowColor = 'rgba(0, 0, 0, 0.3)'
      let x = 80 / 5 * (i + 1)
      let y = 39 / 2
      let deg = randomNum(-25, 25)
      /**设置旋转角度和坐标原点**/
      ctx.translate(x, y)
      ctx.rotate(deg * Math.PI / 180)
      ctx.fillText(char, 0, 0)
      /**恢复旋转角度和坐标原点**/
      ctx.rotate(-deg * Math.PI / 180)
      ctx.translate(-x, -y)
    }
    setCCode(code)
  }

  const confirmHandle = () => {
    if (!content) {
      alert('请输入您的留言内容')
      return
    }
    if (!name) {
      alert('请输入您的姓名')
      return
    }
    const reg = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[189]))\d{8}$/
    if (!phone) {
      alert('请输入您的联系电话')
      return
    }
    if (!reg.test(phone)) {
      alert('请输入正确的电话号码')
      return
    }
    if (!code) {
      alert('请输入验证码')
      return
    }
    if (code.toLocaleLowerCase() !== cCode.toLocaleLowerCase()) {
      alert('请输入正确的验证码')
      return
    }
    // name,company,content,phone,code
    axios({
      method: 'GET',
      url: 'http://api.zrarc.com/createRemark',
      params: {
        content,
        name,
        company,
        phone,
        code
      }
    }).then(res => {
      if (res.data.code === 200) {
        createCode()
        setContent('')
        setName('')
        setCompany('')
        setPhone('')
        setCode('')
        alert('感谢您的留言，稍后会有专人和您联系')
      } else {
        alert(res.data.data)
      }
    })
  }

  return (
    <div className="index-page">
      <Header />
      <div className="swiper-container">
        <Swiper
          slidesPerView={1}
          initialSlide={0}
          pagination={{ clickable: true }}
          loop={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
            pauseOnMouseEnter: false
          }}
          onSlideChange={(event) => {
            setBannerIndex(event.realIndex)
          }}
        >
          {
            bannerList.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="img-box"
                    style={{
                      background: `url(${item.img}) no-repeat center`,
                      backgroundSize: `cover`
                    }}
                  >
                    <p className={index === bannerIndex ? 'active' : undefined}>{item.name}</p>
                  </div>
                </SwiperSlide>
              )
            })
          }
        </Swiper>
      </div>
      <Title title="关于我们" icon="&#xe63c;" size={30} />
      <div className="auto about">
        <p className="title animate">公司简介</p>
        <p className="desc animate">中壬建筑设计有限公司，在建筑设计以及工程施工和勘察领域都有丰富的经验累积，公司团队核心人员拥有二十余年的技术沉淀以及管理经验。</p>
        <Link className="more animate" to="/about"><span>&gt;</span>&nbsp;了解更多</Link>
      </div>
      <Title title="业务领域" showMore icon="&#xe60c;" to="/about" />
      <ul className="auto ywlw">
        <li className="animate" style={{animationDelay: 0}}>
          <img className="img" src={ICONFASJ} alt="方案设计" />
          <span className="txt">方案设计</span>
        </li>
        <li className="animate" style={{animationDelay: '.1s'}}>
          <img className="img" src={ICONGCSJ} alt="工程设计" />
          <span className="txt">工程设计</span>
        </li>
        <li className="animate" style={{animationDelay: '.15s'}}>
          <img className="img" src={ICONSZSJ} alt="市政设计" />
          <span className="txt">市政设计</span>
        </li>
        <li className="animate" style={{animationDelay: '.2s'}}>
          <img className="img" src={ICONCXGH} alt="城乡规划" />
          <span className="txt">城乡规划</span>
        </li>
        <li className="animate" style={{animationDelay: '.25s'}}>
          <img className="img" src={ICONEPC} alt="EPC总包" />
          <span className="txt">EPC总包</span>
        </li>
        <li className="animate" style={{animationDelay: '.3s'}}>
          <img className="img" src={ICONZHJZ} alt="智慧建筑" />
          <span className="txt">智慧建筑</span>
        </li>
      </ul>
      <Title title="数字城市与智慧楼宇" showMore icon="&#xe638;" size={36} to="/wisdom" />
      <img src={DP1} className="auto szcs-img animate" />
      <Title title="在线留言" icon="&#xe61d;" size={32} />
      <div className="auto zxly-ct">
        <textarea className="animate" placeholder="请填写您的留言..." value={content} onChange={(e) => setContent(e.target.value)}></textarea>
        <div className="right">
          <div className="at-row">
            <input className="input1 animate" type="text" placeholder="请填写您的姓名" value={name} onChange={(e) => setName(e.target.value)} />
            <input className="input1 animate" type="text" placeholder="请填写您的公司" value={company} onChange={(e) => setCompany(e.target.value)} />
          </div>
          <input 
            className="input2 animate" 
            type="text" 
            placeholder="请填写您的联系电话" 
            value={phone} 
            onChange={(e) => setPhone(e.target.value)} 
            maxLength={11}
          />
          <div className="right-bottom">
            <input className="animate" type="text" maxLength={4} value={code} onChange={(e) => setCode(e.target.value)} />
            <div className="c-box animate">
              <canvas onClick={createCode} width="80" height='39' id="myCanvas" />
            </div>
            <span className="btn animate" onClick={confirmHandle}>确认提交</span>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}