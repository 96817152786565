import React from 'react';
import { Link } from 'react-router-dom';
import './index.scss';

export default (props) => {
  const { icon, title, size = 24, showMore = false, to = '/index' } = props;
  return (
    <div className="sec-title animate fadeInUp1">
      <div className="sec-title-main">
        {
          icon && <span className="iconfont" style={{fontSize: size, color: '#999'}}>{icon}</span>
        }
        <span className="titleText">{title}</span>
      </div>
      {
        showMore && <Link className="sec-title-more" to={to}><span>&gt;</span>&nbsp;了解更多</Link>
      }
    </div>
  )
}