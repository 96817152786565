import React from 'react';
import LOGO from '../../assets/footer-logo.png';
import CODE from '../../assets/footer-code.png';
import './index.scss';

export default () => {
  return (
    <div className="footer">
      <div className="footer-container">
        <img className="footer-logo animate" src={LOGO} />
        <div className="line animate"></div>
        <div className="footer-center">
          <div>
            <p className="t1 animate">地址</p>
            <p className="animate">北京市海淀区彩和坊路8号天创科技大厦</p>
            <p className="animate">朔州市开发区文远路87号</p>
          </div>
          <div>
            <p className="t2 animate">电话</p>
            <p className="num animate">400-998-2282</p>
          </div>
        </div>
        <div className="footer-right">
          <p className="t1 animate">邮箱</p>
          <p className="animate">zrarc_ty@163.com</p>
          <img className="footer-code animate" src={CODE} />
        </div>
      </div>
      <div className='footer-bottom'>
        <div className='footer-bottom-center'>
          <span>晋ICP备2020008294号-1</span>
        </div>
      </div>
    </div>
  )
}