import React, { useState } from 'react';
import axios from 'axios';
import './index.scss';



export default () => {
  const [content, setContent] = useState('')
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')

  const confirmHandle = () => {
    if (!content) {
      alert('请输入您的留言内容')
      return
    }
    if (!name) {
      alert('请输入您的姓名')
      return
    }
    const reg = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[189]))\d{8}$/
    if (!phone) {
      alert('请输入您的联系电话')
      return
    }
    if (!reg.test(phone)) {
      alert('请输入正确的电话号码')
      return
    }
    axios({
      method: 'GET',
      url: 'http://api.zrarc.com/createRemark',
      params: {
        content,
        name,
        phone,
      }
    }).then(res => {
      if (res.data.code === 200) {
        setContent('')
        setName('')
        setPhone('')
        alert('感谢您的留言，稍后会有专人和您联系')
      } else {
        alert(res.data.data)
      }
    })
  }

  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  return (
    <div className="side-tab">
      <div className="item">
        <span className="iconfont">&#xe649;</span>
        <div className="item-show item-pshow">
          <p className="t1">业务联系</p>
          <p className="t2">Business Contact</p>
          <p className="t3">400-998-2282</p>
          <p className="t4">咨询合作详情</p>
        </div>
      </div>
      <div className="item">
        <span className="iconfont" style={{fontSize: 30}}>&#xe646;</span>
        <div className="item-show item-cshow">
          <img className="code" src={require('../../assets/footer-code.png').default} />
        </div>
      </div>
      <div className="item">
        <span className="iconfont">&#xe60a;</span>
        <div className="item-show item-mshow">
          <p className="t1">访客留言</p>
          <p className="t2">Contact Us</p>
          <div className="row">
            <input 
              className="input1" 
              type="text" 
              placeholder="姓名" 
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input 
              className="input2" 
              type="text" 
              placeholder="联系方式" 
              maxLength={11} 
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <textarea 
            placeholder="请填写您的留言..." 
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
          <div className="btn-wrap">
            <span className="btn" onClick={confirmHandle}>确认提交</span>
          </div>
        </div>
      </div>
      <div className="item" onClick={scrollToTop}>
        <span className="iconfont">&#xe692;</span>
      </div>
    </div>
  )
}