import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import TXET from '../../assets/partner-text.png';
import CODE from '../../assets/footer-code.png';
import HOMEPIC from '../../assets/partner-home.png';
import PEOPLEPIC from '../../assets/partner-people.png';
import MAPPIC from '../../assets/partner-map.png';
import BANNER01 from '../../assets/banner/banner-01.jpg';
import BANNER02 from '../../assets/banner/banner-02.jpg';
import BANNER03 from '../../assets/banner/banner-03.jpg';
import BANNER04 from '../../assets/banner/banner-04.jpg';
import BANNER05 from '../../assets/banner/banner-05.jpg';
import my_animation from '../../animate';
import 'swiper/swiper.scss';
import './index.scss';

SwiperCore.use([Pagination, Autoplay]);

const bannerList = [
  {
    img: BANNER01,
    name: '平鲁区地理信息产业创新孵化基地',
  }, 
  {
    img: BANNER02,
    name: '山西农谷农副食品加工园区'
  }, 
  {
    img: BANNER03,
    name: '介休市汾秀学校建设项目'
  }, 
  {
    img: BANNER04,
    name: '朔州市大营街25号院基督教堂'
  }, 
  {
    img: BANNER05,
    name: '大同市大十字街区改造'
  }
]
export default () => {
  const [bannerIndex, setBannerIndex] = useState('')

  useEffect(() => {
    my_animation()
    setBannerIndex(0)
  }, [])

  return (
    <div className="partner-page">
      <Header />
      <div className="swiper-container">
      <Swiper
          slidesPerView={1}
          initialSlide={0}
          pagination={{ clickable: true }}
          loop={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
            pauseOnMouseEnter: false
          }}
          onSlideChange={(event) => {
            setBannerIndex(event.realIndex)
          }}
        >
          {
            bannerList.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="img-box"
                    style={{
                      background: `url(${item.img}) no-repeat center`,
                      backgroundSize: `cover`
                    }}
                  >
                    <p className={index === bannerIndex ? 'active' : undefined}>{item.name}</p>
                  </div>
                </SwiperSlide>
              )
            })
          }
        </Swiper>
        <div className="slogan animate fadeInUp1">
          <div className="slogan-left">
            <img src={TXET} />
          </div>
          <div className="slogan-right">
            <p className="title animate">置顶</p>
            <p className="txt animate">公司团队深耕建筑设计及相关行业二十余年，拥有建筑工程甲级资质，以及城市规划设计与市政行业等相关资质。</p>
            <p className="txt animate">现<span className="red">诚邀</span>行业内优秀团队及个人，以设立分公司、项目合作等形式协同发展。</p>
            <p className="txt animate">公司将以服务为本的理念，与合作团队共享资源与经验，且保证各团队利益最大化，工作高效化。</p>
            <div className="slogan-right-b animate">
              <div>
                <p className="sub"><span className="iconfont">&#xe64d;</span>合作热线：</p>
                <p className="num">400-998-2282</p>
              </div>
              <img className="code" src={CODE} />
            </div>
          </div>
        </div>
      </div>
      <div className="home">
        <img className="animate fadeInUp2" src={HOMEPIC} />
        <div className="home-center">
          <p className="animate">公司注重将核心团队技术能力与管理执行相结合，且秉承诚信为本、互利共赢的原则，致力于更好的服务于客户及各地区合作伙伴。</p>
          <img className="animate" src={PEOPLEPIC} style={{animationDelay: '.1s'}} />
        </div>
        <img className="animate fadeInUp2" src={MAPPIC} />
      </div>
      <ul className="cmps">
        <li className="cmps-item cmps-item1 animate" style={{animationDelay: 0}}>
          <p className="t1">中壬建筑<span className="dot"></span>山西</p>
          <p className="t2">山西省朔州市开发区文远路87号</p>
        </li>
        <li className="cmps-item cmps-item2 animate" style={{animationDelay: '.1s'}}>
          <p className="t1">中壬建筑<span className="dot"></span>北京</p>
          <p className="t2">北京市海淀区彩和坊路8号天创科技大厦</p>
        </li>
        <li className="cmps-item cmps-item3 animate" style={{animationDelay: '.2s'}}>
          <p className="t1">中壬建筑<span className="dot"></span>青海</p>
          <p className="t2">青海省西宁市城西区万达中心4号写字楼</p>
        </li>
        <li className="cmps-item cmps-item4 animate" style={{animationDelay: 0}}>
          <p className="t1">中壬建筑<span className="dot"></span>福州</p>
          <p className="t2">福建省福州市仓山区维温产业园1号楼</p>
        </li>
        <li className="cmps-item cmps-item5 animate" style={{animationDelay: '.1s'}}>
          <p className="t1">中壬建筑<span className="dot"></span>东莞</p>
          <p className="t2">广东省东莞市南城区高盛科技大厦</p>
        </li>
        <li className="cmps-item cmps-item6 animate" style={{animationDelay: '.2s'}}>
          <p className="t1">中壬建筑<span className="dot"></span>吕梁</p>
          <p className="t2">山西省吕梁市离石区状元城一号</p>
        </li>
      </ul>
      <Footer />
    </div>
  )
}