import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import SideTab from  '../SideTab';
import './index.scss';

export default () => {
  const href = window.location.hash.split('#/')[1]
  const navList = ['index', 'about', 'wisdom', 'honor', 'partner', 'contact']
  const navIndex = navList.findIndex((i) => i === href)

  useEffect(() => {
    if (navIndex === 4) {
      let timer = setInterval(() => {
        if (document.getElementById('newBridge')) {
          clearInterval(timer)
          timer = null
          document.getElementById('newBridge').setAttribute('class', 'partner-modal')
        }
      }, 100);
    } else {
      let timer = setInterval(() => {
        if (document.getElementById('newBridge')) {
          clearInterval(timer)
          timer = null
          document.getElementById('newBridge').setAttribute('class', '')
        }
      }, 100);
    }
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (window.screen.width <= 1366) {
      window.location.href = 'http://mobile.zrarc.com'
    }
  }, [])

  return (
    <>
      <SideTab />
      <div className="header">
        <div className="header-name">
          <span>中壬建筑</span>
          <span className="dot"></span>
          <span>ZRARC</span>
        </div>
        <ul className="nav-list">
          <li className={navIndex === 0 ? 'active': ''}>
            <Link to="/index">
              <span className="iconfont">&#xe634;</span>
              <span>首页</span>
            </Link>
          </li>
          <li className={navIndex === 1 ? 'active': ''}>
            <Link to="/about">
              <span className="iconfont" style={{fontSize: 20}}>&#xe6b1;</span>
              <span>关于我们</span>
            </Link>
          </li>
          <li className={navIndex === 2 ? 'active': ''}>
            <Link to="/wisdom">
              <span className="iconfont" style={{marginTop: 2}}>&#xe60f;</span>
              <span>智慧建筑</span>
            </Link>
          </li>
          <li className={navIndex === 3 ? 'active': ''}>
            <Link to="/honor">
              <span className="iconfont" style={{marginTop: 2}}>&#xe72d;</span>
              <span>资质荣誉</span>
            </Link>
          </li>
          <li className={navIndex === 4 ? 'active': ''}>
            <Link to="/partner">
              <span className="iconfont" style={{marginTop: 2}}>&#xe60e;</span>
              <span>城市合伙人</span>
            </Link>
          </li>
          <li className={navIndex === 5 ? 'active': ''}>
            <Link to="/contact">
              <span className="iconfont" style={{marginTop: 1}}>&#xe64d;</span>
              <span>联系我们</span>
            </Link>
          </li>
        </ul>
      </div>
    </>
  )
}