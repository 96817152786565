import { Route, Switch, Redirect, HashRouter, withRouter } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import Index from './pages/index';
import About from './pages/about';
import Wisdom from './pages/wisdom';
import Honor from './pages/honor';
import Contact from './pages/contact';
import Partner from './pages/partner';
import './App.css';

function App() {
  return (
    <HashRouter>
      <Switch>
        {/* <ScrollToTop> */}
          <Route exact path="/index" component={Index} />
          <Route exact path="/about" component={About} />
          <Route exact path="/wisdom" component={Wisdom} />
          <Route exact path="/honor" component={Honor} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/partner" component={Partner} />
          <Redirect exact from="/" to="/index" />
        {/* </ScrollToTop> */}
      </Switch>
    </HashRouter>
  );
}

export default App;
